import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Register from "@/views/Register.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";
import User from "@/views/User.vue";
import Info from "@/views/Info.vue";
import UserList from '@/views/UserList.vue';
import ProfileEdit from '@/views/ProfileEdit.vue';
import UserImage from '@/views/Image.vue';

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/terms",
      name: "terms",
      component: Terms
    },
    {
      path: "/privacy",
      name: "privacy",
      component: Privacy
    },
    {
      path: "/user/:uid",
      name: "user",
      component: User
    },
    {
      path: "/_user/:uid",
      redirect: "/user/:uid"
    },
    {
      path: "/user/:uid/image/:id",
      name: "user-image",
      component: UserImage
    },
    {
      path: "/_user/:uid/image/:id",
      redirect: "/user/:uid/image/:id"
    },
    {
      path: "/user/:uid/date/:date",
      name: "user-date",
      component: UserImage
    },
    {
      path: "/_user/:uid/date/:date",
      redirect: "/user/:uid/date/:date"
    },
    {
      path: "/info",
      name: "info",
      component: Info
    },
    {
      path: "/user-list",
      name: "user-list",
      component: UserList
    },
    {
      path: "/profile-edit",
      name: "profile-edit",
      component: ProfileEdit
    },
  ]
});
