
export enum AchieveType
{
  None = 0,
  KeepOn3 = 1 << 0,
  KeepOn10 = 1 << 1,
  KeepOn30 = 1 << 2,
  KeepOn50 = 1 << 3,
  KeepOn100 = 1 << 4,
  KeepOn184 = 1 << 5,
  KeepOn365 = 1 << 6,
}

export default class AchieveInfo
{
  type: AchieveType;
  count: number;
  tooltip: string;
  color: string;

  constructor(type: AchieveType, count: number, tooltip: string, color: string)
  {
    this.type = type;
    this.count = count;
    this.tooltip = tooltip;
    this.color = color;
  }

  /**
   * 継続実績
   */
  static keepOnAchieves(): AchieveInfo[]
  {
    return [
      new AchieveInfo(AchieveType.KeepOn3, 3,"3日達成", "red"),
      new AchieveInfo(AchieveType.KeepOn10, 10, "10日達成", "pink"),
      new AchieveInfo(AchieveType.KeepOn30, 30, "1ヶ月達成", "purple"),
      new AchieveInfo(AchieveType.KeepOn50, 50, "50日達成", "deep-purple"),
      new AchieveInfo(AchieveType.KeepOn100, 100, "100日達成", "indigo"),
      new AchieveInfo(AchieveType.KeepOn184, 184, "半年達成", "blue"),
      new AchieveInfo(AchieveType.KeepOn365, 365, "1年達成", "light-blue"),
    ];
  }
}
