
  import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
  import UserProfile from '@/components/UserProfile.vue';
  import Uploader from "@/components/Uploader.vue";
  import UserModel from '@/models/user';
  import AppStore from '@/store';
  import ImageModel from '@/models/image';
  import Timestamp = firebase.firestore.Timestamp;
  import ImageCard from '@/components/ImageCard.vue';
  import ModalDialog from '@/components/ModalDialog.vue';
  import ImageDialog from '@/components/ImageDialog.vue';
  import MonthPicker from '@/components/MonthPicker.vue';
  import {Route} from 'vue-router';
  import StoreMixin from "@/store-mixin";
  import firebase from 'firebase';
  import Utility from '@/plugins/utility';

  @Component({
    components: {
      MonthPicker,
      ImageDialog,
      ModalDialog,
      ImageCard,
      Uploader,
      UserProfile,
    }
  })

  /**
   * ユーザーページ
   */
  export default class User extends Mixins(StoreMixin)
  {
    name: string="user";

    user: UserModel | null = null;
    userId: string = '';

    currentYear: number = 0;
    currentMonth: number = 0;
    imageList: ImageModel[] = [];

    // image dialog
    isOpenImage: boolean = false;
    selectedImage: ImageModel = new ImageModel("", "", "", new UserModel(null));

    loading: boolean = false;

    get isMyAccount(): boolean
    {
      if(!this.isLogin)
      {
        return false;
      }
      return this.myUserModel.id == this.userId;
    }

    /**
     * 画像リストをアップデート
     */
    private async updateImages(): Promise<ImageModel[]>
    {
      const images = await AppStore.I.getImageModelByMonth(this.userId, this.currentYear, this.currentMonth) as ImageModel[];
      images.sort((a,b)=>{return (b.createdAt as Timestamp).seconds - (a.createdAt as Timestamp).seconds});
      this.imageList = images;
      return images;
    }

    async updateUser(updatedUser: UserModel)
    {
      this.user = updatedUser;
      await this.updateImages();
    }

    /**
     * カードクリック時
     * @param image
     */
    private onClickCard(image: ImageModel)
    {
      // this.jumpToUserImage(this.userId, image.id);
      this.isOpenImage = true;
      this.selectedImage = image;
    }

    /**
     * 画像のタイトルを取得
     * @param index
     */
    getImageListTitle(index: number): string
    {
      const image = this.imageList[index];
      const date = new Date((image.createdAt as Timestamp).seconds * 1000);
      if(0 == index)
      {
        return "■" +(date.getMonth() + 1) + "月 " + date.getDate() + "日";
      }

      const prev = this.imageList[index - 1];
      if(image.path == prev.path)
      {
        return "　";
      }
      else
      {
        return "■" +(date.getMonth() + 1) + "月 " + date.getDate() + "日";
      }
    }

    /**
     * 画像を閉じたとき呼ばれる
     */
    onCloseImage()
    {
      this.isOpenImage = false;
    }

    /**
     * 日付を更新
     * @param year
     * @param month
     */
    updateDate(year: number, month: number)
    {
      this.currentYear = year;
      this.currentMonth = month;
    }

    @Watch('$route', { immediate: true, deep: true })
    async onRouteChange(to: Route)
    {
      const uid = this.$route.params.uid;

      if(!uid)
      {
        await this.jumpToHome();
        return;
      }

      this.userId = uid;
      this.user = await AppStore.I.getUserModel(this.userId);

      if(null === this.user)
      {
        await this.jumpToHome();
        return;
      }

      const imageParam = this.$route.params.image;
      // solve image
      if(imageParam)
      {
        const imageId = imageParam;
        const image = await AppStore.I.getImageModel(imageId, this.userId);
        if(null != image)
        {
          this.onClickCard(image);
        }
      }

      // get image lists
      const now = new Date();
      this.currentYear = now.getFullYear();
      this.currentMonth = now.getMonth();

      const images = await this.updateImages();
      if(!images || images.length <= 0)
      {
        // 現在の月がなかったら最終の投稿月を表示する
        const date = Utility.convertDate(this.user.postedAt);
        this.updateDate(date.getFullYear(), date.getMonth());
        await this.updateImages();
      }

      this.loading = true;
    }
  }
