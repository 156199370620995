
  import {Component, Mixins, Prop, Vue} from "vue-property-decorator";

  @Component
  export default class Logo extends Vue
  {
    name: string = "logo";

    onClick()
    {
      this.$emit('on-click');
    }
  }
