
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
  import ImageModel from '@/models/image';
  import AppStore from '@/store';
  import ImageCard from '@/components/ImageCard.vue';
  import firebase from 'firebase';
  import Timestamp = firebase.firestore.Timestamp;
  import UserModel from '@/models/user';
  import ImageDialog from '@/components/ImageDialog.vue';
  import {DocumentModel} from '@/plugins/firestore';
  import StoreMixin from "@/store-mixin";

  @Component({
    components: {
      ImageCard,
      ImageDialog
    }
  })

  export default class TodayImageList extends StoreMixin
  {
    readonly Count: number = 20;

    name: string = "today-image-list";

    // image dialog
    isOpenImage: boolean = false;
    selectedImage: ImageModel = new ImageModel("", "", "", new UserModel(null));

    isUpdateEnabled: boolean = false;

    images: DocumentModel<ImageModel>[] = [];

    async created()
    {
      await this.updateImage();
    }

    private sleep(msec: number)
    {
      return new Promise(resolve => setTimeout(resolve, msec));
    }

    async onDelete(_: UserModel)
    {
      this.updateImage()
    }

    public async updateImage()
    {
      this.isUpdateEnabled = false;
      this.images = await AppStore.I.getRecentImageList(this.Count);
      // this.images.sort((a,b)=>{return (b.model!.createdAt as Timestamp).seconds - (a.model!.createdAt as Timestamp).seconds});

      // 連打対策
      await this.sleep(5000);
      this.isUpdateEnabled = true;
    }

    async onClickCard(image: ImageModel)
    {
      await this.jumpToUserImage(image.userId, image.id);
    }

    onCloseImage()
    {
      this.isOpenImage = false;
    }

    async onClickMore()
    {
      if(this.images.length === 0) return;
      const last = this.images[this.images.length - 1];
      if(!last) return ;

      this.isUpdateEnabled = false;

      const add = await AppStore.I.getRecentImageList(this.Count, last.snapshot);
      this.images = this.images.concat(add);

      this.isUpdateEnabled = true;
    }
  }
