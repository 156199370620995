
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class MonthPicker extends Vue
  {
    private name:string = "month-picker";

    @Prop()
    private currentYear!: number;

    @Prop()
    private currentMonth!: number;

    private isPickerModalOpen: boolean = false;
    private date: string = new Date().toISOString().substr(0, 7);

    private onClickNext()
    {
      let month = this.currentMonth + 1;
      let year = this.currentYear;
      if(month > 11)
      {
        month = 0;
        year++;
      }
      this.$emit('update-date', year, month);
      this.$emit('update-image');
    }

    private onClickPrev()
    {
      let month = this.currentMonth - 1;
      let year = this.currentYear;
      if(month < 0)
      {
        month = 11;
        year--;
      }
      this.$emit('update-date', year, month);
      this.$emit('update-image');
    }

    private onDecideMonth()
    {
      this.isPickerModalOpen = false;

      var dialog = this.$refs.dialog as any;
      dialog?.save(this.date);
      const date = new Date(this.date);
      let month = date.getMonth();
      let year = date.getFullYear();

      this.$emit('update-date', year, month);
      this.$emit('update-image');
    }
}
