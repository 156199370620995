
  import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
  import UserModel from '@/models/user';
  import UserAchieve from '@/components/UserAchieve.vue';
  import StoreMixin from '@/store-mixin';
  import {mdiTwitter} from '@mdi/js';
import Utility from '@/plugins/utility';

  @Component({
    components: {
      UserAchieve,
    }
  })

  export default class UserProfile extends Mixins(StoreMixin)
  {
    name:string = "user-profile";
    profileUrl: string = "";
    iconTwitter: string = mdiTwitter;

    @Prop()
    user!: UserModel;

    @Prop()
    comment!: string;

    onClickTwitter()
    {

    }

    get dateCount()
    {
      if (Utility.isContinue(this.user.postedAt)){
        return `継続${this.user.count}日目/累計${this.user.postCount}枚/最高継続${this.user.bestCount}日`
      }
      return `累計${this.user.postCount}枚/最高継続${this.user.bestCount}日`
    }
  }
