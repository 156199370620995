
  import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
  import AppStore from "@/store";
  import {mdiInformationOutline} from "@mdi/js";
  import StoreMixin from '@/store-mixin';
  import LoginDialog from '@/components/LoginDialog.vue';
  import Logo from '@/components/Logo.vue';

  @Component({
    components: {
      LoginDialog,
      Logo
    }
  })
  export default class Header extends Mixins(StoreMixin)
  {
    name: string = "header";

    infoIcon: string = mdiInformationOutline;

    get getVersion()
    {
      return "　v" + AppStore.I.newVersion;
    }

    get getWidth()
    {
      return this.$vuetify.breakpoint.width;
    }

    async onClickMyPage()
    {
      const user = this.myUserModel;

      if(null == user)
      {
        return;
      }

      const route = this.jumpToUser(user.id);
    }

    async onClickLogo()
    {
      const route = this.jumpToHome();
    }

    async onClickLogout()
    {
      await AppStore.I.signOut();
      this.openSnackbar('ログアウトしました。');
      this.updateMyUser(null);
    }

    async onClickInfo()
    {
      const route = this.jumpTo('info');
    }

    onClickMenu()
    {
      this.isDrawerOpen = true;
    }

    onClickUserList()
    {
      const route = this.jumpTo('user-list');
    }

    onClickEditProfile()
    {
      const route = this.jumpTo('profile-edit');
    }
  }
