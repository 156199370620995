import {Mixins} from 'vue-property-decorator';
import FirebaseClient from '@/plugins/firebase';
import UserModel from '@/models/user';
import * as firebase from 'firebase';
import ImageModel, {UserComment} from '@/models/image';
import FirestoreClient, {DocumentModel} from '@/plugins/firestore';
import Utility from '@/plugins/utility';
import AppStorage from '@/plugins/app-storage';
import VersionControl from '@/plugins/version-control';
import StoreMixin from '@/store-mixin';
import Timestamp = firebase.firestore.Timestamp;
import CloudStorageClient from '@/plugins/cloudstorage';
import {crc32} from 'crc';
import AchieveInfo, {AchieveType} from '@/models/achieve-info';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export default class AppStore extends Mixins(StoreMixin)
{
  private versionKey: string = 'info-version';

  public cloudStorage: CloudStorageClient;

  private static sharedInstance: AppStore;

  /**
   * Firestore
   */
  private firestore: FirestoreClient;
  private storage: AppStorage = new AppStorage();
  private currentVersion: VersionControl;
  newVersion: string;

  private constructor()
  {
    super();

    this.firestore = new FirestoreClient(FirebaseClient.Client.fireStore);
    this.cloudStorage = new CloudStorageClient(FirebaseClient.Client.cloudStorage);
    let localVersion: string = this.storage.getFromLocal(this.versionKey) || '0.0.0';
    this.currentVersion = new VersionControl(localVersion);

    const newVersion = VersionControl.Create(process.env.VUE_APP_VERSION);
    this.newVersion = newVersion.versionString;
    if(newVersion.isHigher(this.currentVersion))
    {
      this.updateIsInfoRead(true);
    }
  }

  public static get I(): AppStore
  {
    if (!this.sharedInstance)
    {
      this.sharedInstance = new AppStore();
    }
    return this.sharedInstance;
  }

  /**
   * Twitterログイン
   * 登録されていないときはnullを返す
   */
  public async signInWithTwitter(): Promise<UserModel | null>
  {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.signIn(provider);
  }

  /**
   * Googleログイン
   * 登録されていないときはnullを返す
   */
  public async signInWithGoogle(): Promise<UserModel | null>
  {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.signIn(provider);
  }

  private async signIn(provider: firebase.auth.AuthProvider): Promise<UserModel | null>
  {
    const userCredential = await FirebaseClient.Client.firebaseApp.auth().signInWithPopup(provider);
    const firebaseUser = userCredential.user;
    if(null == firebaseUser) throw new Error(provider.providerId + 'のログインに失敗しました');

    const user = await this.getUserModel(firebaseUser.uid);
    if(null == user) return null;

    user.updatedAt = new Date();
    await this.firestore.set<UserModel>('users', user);
    return user;
  }

  /**
   * Googleアカウントを使って登録
   */
  public async registerWithGoogle(): Promise<UserModel>
  {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.register(provider);
  }

  /**
   * Twitterアカウントを使って登録
   */
  public async registerWithTwitter(): Promise<UserModel>
  {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.register(provider);
  }

  private async register(provider: firebase.auth.AuthProvider): Promise<UserModel>
  {
    const userCredential = await FirebaseClient.Client.firebaseApp.auth().signInWithPopup(provider);
    const firebaseUser = userCredential.user;
    if(null == firebaseUser) throw new Error('Googleのログインに失敗しました');

    const user = await this.getUserModel(firebaseUser.uid);
    // 登録済み
    if(null != user)
    {
      user.updatedAt = new Date();
      await this.firestore.set<UserModel>('users', user);
      return user;
    }

    // 登録処理
    const newUser = new UserModel(firebaseUser);
    await this.firestore.set<UserModel>('users', newUser);
    return newUser;
  }

  /**
   * ログアウト
   */
  public async signOut(): Promise<void>
  {
    await FirebaseClient.Client.firebaseApp.auth().signOut();
  }

  /**
   * ユーザーを取得
   * @param uid
   */
  public async getUserModel(uid: string): Promise<UserModel | null>
  {
    return await this.firestore.get<UserModel>('users', uid);
  }

  /**
   * ユーザーを更新
   * @param user
   */
  public async setUserModel(user: UserModel): Promise<void>
  {
    return await this.firestore.set<UserModel>('users', user);
  }

  /**
   * イメージを取得
   * @param id
   * @param userId
   */
  public async getImageModel(id: string, userId: string): Promise<ImageModel | null>
  {
    return await this.firestore.get<ImageModel>('images', id, {'users':userId});
  }

  /**
   * その月のImageをUserごとに取得
   * @param uid
   * @param year
   * @param month
   */
  public async getImageModelByMonth(uid: string, year: number, month: number): Promise<(ImageModel | null)[]>
  {
    const from  = new Date();
    from.setFullYear(year);
    from.setMonth(month);
    from.setDate(1);
    from.setHours(0,0,0,0);
    const to = new Date();
    to.setFullYear(year);
    to.setMonth(month + 1);
    to.setDate(0);
    to.setHours(23, 59,59,99);

    const query = this.firestore.db.collectionGroup('images')
      .where('userId', '==', uid)
      .where('createdAt', ">=", from)
      .where('createdAt', "<=", to)
      .orderBy('createdAt', 'desc');

    return this.firestore.getQueryResult<ImageModel>(query);
  }

  /**
   * 日時を指定して画像を取得する
   * @param uid
   * @param date
   */
  public async getImageModelByDay(uid: string, date: Date): Promise<(ImageModel | null)>
  {
    const from  = new Date(date.getTime());
    from.setHours(0,0,0,0);
    const to = new Date(date.getTime());
    to.setHours(23, 59,59,99);

    const query = this.firestore.db.collectionGroup('images')
      .where('userId', '==', uid)
      .where('createdAt', ">=", from)
      .where('createdAt', "<=", to)
      .orderBy('createdAt', 'desc');

    const list = await this.firestore.getQueryResult<ImageModel>(query);
    return list.shift() as ImageModel | null;
  }

  /**
   * みんなの新着画像を取得
   * @param limit
   * @param cursor
   */
  public async getRecentImageList(limit: number, cursor: DocumentSnapshot | null = null): Promise<DocumentModel<ImageModel>[]>
  {
    let query = this.firestore.db.collectionGroup('images')
      .orderBy('createdAt', 'desc')
      .limit(limit);

    if(null != cursor)
    {
      query = query.startAfter(cursor);
    }

    return this.firestore.getQuery<ImageModel>(query);
  }

  /**
   * イメージモデルを投稿
   * @param model
   */
  public async insertImage(model: ImageModel): Promise<void>
  {
    await this.firestore.set("images", model, {'users' : model.userId});
  }

  /**
   * userModelのポスト数を計算してset
   * @param userModel
   */
  public async updatePostCount(userModel: UserModel): Promise<{
    isReset: boolean,
    isUpdate: boolean,
    info: AchieveInfo,
    count: number,
    isBest: boolean
  }>
  {
    let isUpdate = true;
    let isReset = false;
    let isBest = false;

    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentPost: Date = Utility.convertDate(userModel.postedAt);

    if(currentPost.getFullYear() == now.getFullYear()
      && currentPost.getMonth() == now.getMonth()
      && currentPost.getDate() == now.getDate())
    {
      // 同日更新はカウントされない
      isUpdate = false;
    }
    else if(currentPost.getFullYear() == yesterday.getFullYear()
      && currentPost.getMonth() == yesterday.getMonth()
      && currentPost.getDate() == yesterday.getDate())
    {
      // 昨日だったら + 1
      userModel.count++;
    }
    else if(currentPost.getFullYear() < yesterday.getFullYear())
    {
      userModel.count = 1;
      isReset = true;
    }
    else if(currentPost.getMonth() < yesterday.getMonth())
    {
      userModel.count = 1;
      isReset = true;
    }
    else if(currentPost.getDate() < yesterday.getDate())
    {
      userModel.count = 1;
      isReset = true;
    }
    else
    {
      // 未来...?
      isUpdate = false;
    }

    if(isUpdate)
    {
      userModel.postedAt = now;
    }

    const count = userModel.count;
    if(userModel.count > userModel.bestCount)
    {
      isBest = true;
      userModel.bestCount = userModel.count;
    }

    if(!userModel.postCount){
      userModel.postCount = 0;
    }
    userModel.postCount++;

    const info = this.checkKeepOnAchieve(userModel);

    await this.firestore.set<UserModel>("users", userModel);

    return {isReset, isUpdate, info, count, isBest};
  }

  /**
   * 継続実績を解除
   * @param userModel
   */
  private checkKeepOnAchieve(userModel: UserModel): AchieveInfo
  {
    let info: AchieveInfo = new AchieveInfo(AchieveType.None, 0, "", "");
    if(null == userModel)
    {
      return info;
    }

    const best = userModel.bestCount;
    const achieves = AchieveInfo.keepOnAchieves();
    achieves.forEach((acv: AchieveInfo)=>
    {
      if(acv.count <= best)
      {
        userModel.achieveFlags |= acv.type;
        if(info.count < acv.count)
        {
          info = acv;
        }
      }
    });
    return info;
  }

  /**
   * お知らせを既読にする
   */
  public markInformation()
  {
    const newVersion = VersionControl.Create(process.env.VUE_APP_VERSION);
    if(this.currentVersion.isHigher(newVersion))
    {
      this.storage.setFromLocal(this.versionKey, newVersion.versionString);
      this.currentVersion = newVersion;
      this.updateIsInfoRead(true);
    }
  }

  /**
   * 日毎の画像を投稿する
   * @param name
   * @param date
   * @param data
   * @param contentType
   * @param userId
   */
  async uploadDailyImage(name: string, date: Date, data: ArrayBuffer, contentType: string, userId: string): Promise<firebase.storage.UploadTaskSnapshot>
  {
    const fileName = crc32(name + "." + date.toDateString()).toString() + ".jpg";
    const dayName = date.getFullYear().toString() + date.getMonth().toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0');
    const path = 'users/' + userId + '/daily/' + dayName + "/" + fileName;
    return this.cloudStorage.upload(path, data, contentType).then();
  }

  /**
   * ユーザーのアイコンを投稿する
   * @param data
   * @param userId
   */
  async uploadUserIcon(data: ArrayBuffer, userId: string): Promise<firebase.storage.UploadTaskSnapshot>
  {
    const contentType: string = 'image/jpeg';
    const fileName = "icon.jpg";
    const path = 'users/' + userId + '/icon/' + fileName;
    return this.cloudStorage.upload(path, data, contentType).then();
  }

  /**
   * firebaseのユーザーを取得する
   */
  async getFirebaseUser(): Promise<firebase.User | null>
  {
    return new Promise<firebase.User | null>((resolve, reject) =>
    {
      FirebaseClient.Client.firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      }, (e: firebase.auth.Error)=>{
        reject(e);
      });
    });
  }

  /**
   * 画像を削除する
   * @param image
   */
  async deleteImage(image: ImageModel): Promise<void>
  {
    const storeDelete = this.firestore.delete('images', image.id,{'users': image.userId});
    const storageDelete = this.cloudStorage.delete(image.path);

    await storeDelete;
    await storageDelete;

    await this.decrementPostCount(image);
  }

  /**
   * 投稿数をデクリメント
   *
   * @param image
   */
  async decrementPostCount(image: ImageModel)
  {
    const postedAt = Utility.convertDate(image.createdAt);

    const user = this.myUserModel;
    if(Utility.isToday(postedAt)){
      // 上げ直し
      const imageRef = this.firestore.db.collection('users').doc(user.id).collection('images');
      const query = imageRef.where('userId', '==', image.userId)
        .orderBy('createdAt', 'desc')
        .limit(1);

      const snapshot = await query.get();
      if(null == snapshot.docs || snapshot.docs.length <= 0)
      {
        user.postedAt = new Date('05 October 2011 14:48 UTC');
        user.count = 0;
      }
      else
      {
        const document = snapshot.docs[0];
        const lastPostedImage = Object.assign({ id: document.id }, document.data()) as ImageModel;
        user.postedAt = Utility.convertDate(lastPostedImage.createdAt);
        user.count = Math.max(user.count - 1, 0);
      }
    }
    user.postCount--;

    await this.firestore.set<UserModel>('users', user);
  }

  /**
   * ユーザーを取得
   * @param offset
   * @param cursor
   */
  async getUsers(offset: number, cursor: DocumentSnapshot | null = null, sort: string = 'count'): Promise<DocumentModel<UserModel>[]>
  {
    let query = this.firestore.db
      .collection('users')
      .where(sort, ">", 0)
      .orderBy(sort, "desc")
      .limit(offset);

    if(null != cursor)
    {
      query = query.startAfter(cursor);
    }

    return this.firestore.getQuery<UserModel>(query);
  }

  async getContinueUsers(offset: number, cursor: DocumentSnapshot | null = null, today: Date): Promise<DocumentModel<UserModel>[]>
  {
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    let query = this.firestore.db
      .collection('users')
      .where('postedAt', '>=', new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()))
      .orderBy('postedAt', 'desc')
      .limit(offset);

    if(null != cursor)
    {
      query = query.startAfter(cursor);
    }

    return this.firestore.getQuery<UserModel>(query);
  }

  /**
   * userとしてcommentをimageに追加する
   * @param image
   * @param user
   * @param comment
   */
  async addComment(image: ImageModel, user: UserModel, comment: string)
  {
    const imageDocRef = this.firestore.db
      .collection('users').doc(image.userId)
      .collection('images').doc(image.id);
    const result = await this.firestore.db.runTransaction(transaction =>
    {
      return transaction.get(imageDocRef).then(imageDoc =>
      {
        if(!imageDoc.exists) return;
        const currentImage = this.firestore.assign<ImageModel>(image.id, imageDoc);

        if(!currentImage) return;

        const commentModel = Object.assign({}, new UserComment(user, comment));
        if(currentImage.userComments)
        {
          currentImage.userComments.push(commentModel);
        }
        else
        {
          currentImage.userComments = [commentModel];
        }

        // もとのイメージのコメントを更新
        image.userComments = currentImage.userComments;

        transaction.set(imageDocRef, Object.assign({}, currentImage));
      });
    }).catch(e => console.error(e));

    return result;
  }

}
