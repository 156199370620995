
  import { Component, Prop, Vue } from "vue-property-decorator";
  import Description from '@/components/Description.vue';
  import {mdiInformationOutline} from '@mdi/js'
  import AppStore from "@/store";

  @Component({
    components: {
      Description
    }
  })

  export default class Info extends Vue
  {
    name: string = "information";
    infoIcon: string = mdiInformationOutline;
    updates: any = [];

    mounted()
    {
      AppStore.I.markInformation();

      this.updates = [
        {
          id: 800,
          name: 'ver 0.8.0',
          children:
            [
              { id: 801, name: 'OGPに対応' },
              { id: 802, name: 'ユーザー一覧に継続中タブをデフォルトで追加' },
            ]
        },
        {
          id: 700,
          name: 'ver 0.7.0',
          children:
            [
              { id: 701, name: 'ユーザーの累計枚数を表示' },
              { id: 702, name: 'ユーザー一覧に累計枚数と継続日数順で表示する機能を追加' },
            ]
        },
        {
          id: 600,
          name: 'ver 0.6.0',
          children:
            [
              { id: 601, name: '画像ページを追加' },
              { id: 602, name: 'トップページにもっと見るを追加' },
              { id: 603, name: '一部レイアウト、UIを変更' },
            ]
        },
        {
          id: 400,
          name: 'ver 0.4.0',
          children:
            [
              { id: 401, name: 'コメント付きでイラストを投稿できる機能を追加' },
              { id: 402, name: '自分や他の人のイラストにコメントできる機能を追加' },
              { id: 403, name: 'ユーザー一覧の並び順を変更' },
            ]
        },
        {
          id: 6,
          name: 'ver 0.3.0',
          children:
            [
              { id: 7, name: 'プロフィール編集を追加' },
              { id: 8, name: 'モバイルでの見た目を変更' },
            ]
        },
        {
          id: 3,
          name: 'ver 0.2.0',
          children:
            [
              { id: 4, name: 'ユーザー一覧を追加' },
              { id: 5, name: '描いていない日はユーザーアイコンの横にマークが出るように' },
            ]
        },
        {
          id: 1,
          name: 'ver 0.1.0',
          children:
            [
              { id: 2, name: 'Picmeterリリース' },
            ]
        },

      ]
    }
  }
