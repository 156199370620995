import firebase from 'firebase';
import * as lodash from 'lodash'
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Query = firebase.firestore.Query;
import UserModel from '@/models/user';
import Transaction = firebase.firestore.Transaction;

const _ = lodash

declare type HasId = {
  id?: string;
};

/**
 * T型のモデルを持ったDocumentSnapshotのラッパークラス
 */
export class DocumentModel<T extends HasId>
{
  model!: T|null;
  snapshot!: DocumentSnapshot;

  constructor(model: T|null, snapshot: DocumentSnapshot)
  {
    this.model = model;
    this.snapshot = snapshot;
  }
}

export default class FirestoreClient
{
  public db: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore)
  {
    this.db = firestore;
  }

  /**
   * T型のモデルを一件取得する
   * idをつけて返す
   * @param collectionName
   * @param id
   * @param path
   */
  public async get<T extends HasId>(collectionName: string, id: string, path: {[colName: string]: string} = {}): Promise<T | null>
  {
    let docRef: firebase.firestore.DocumentReference | null = null;
    for (const col in path)
    {
      docRef = this.db.collection(col).doc(path[col]);
    }

    const collection = null === docRef ? this.db.collection(collectionName) : docRef.collection(collectionName);

    let snapshot = await collection.doc(id).get();
    return this.assign<T>(id, snapshot);
  }

  /**
   * DocumentSnapshot => Model の変換
   * @param id
   * @param snapshot
   */
  public assign<T extends HasId>(id: string, snapshot: DocumentSnapshot): T | null
  {
    if(snapshot.exists)
    {
      return Object.assign({ id: id }, snapshot.data()) as T;
    }
    else
    {
      return null;
    }
  }

  /**
   * ドキュメントを削除
   * @param collectionName
   * @param id
   * @param path
   */
  public async delete(collectionName: string, id: string, path: {[colName: string]: string} = {}): Promise<void>
  {
    let docRef: firebase.firestore.DocumentReference | null = null;
    for (const col in path)
    {
      docRef = this.db.collection(col).doc(path[col]);
    }

    const collection = null === docRef ? this.db.collection(collectionName) : docRef.collection(collectionName);

    await collection.doc(id).delete();
  }



  /**
   * T型のモデルをインサートする
   * idを削除してセットする
   * @param collectionName
   * @param model
   * @param path
   */
  public async set<T extends HasId>(collectionName: string, model: T, path: {[colName: string]: string} = {}): Promise<void>
  {
    const _model = _.cloneDeep(model);
    let docRef: firebase.firestore.DocumentReference | null = null;
    for (const col in path)
    {
      docRef = this.db.collection(col).doc(path[col]);
    }
    const collection = null === docRef ? this.db.collection(collectionName) : docRef.collection(collectionName);

    const id = _model.id;
    delete _model.id;

    const doc = Object.assign({}, _model);
    await collection.doc(id).set(doc);
  }

  /**
   * Queryを実行してDocumentModelを取得
   * @param query
   */
  public async getQuery<T extends HasId>(query: Query) : Promise<DocumentModel<T>[]>
  {
    const ret: DocumentModel<T>[] = [];
    const snapshot = await query.get();
    snapshot.forEach(doc =>
    {
      const dm = new DocumentModel(this.assign<T>(doc.id, doc), doc);
      ret.push(dm);
    });
    return ret;
  }

  /**
   * Modelのみ欲しいとき
   * @param query
   */
  public async getQueryResult<T extends HasId>(query: Query) : Promise<(T | null)[]>
  {
    const result = await this.getQuery(query);
    const ret : (T|null)[] = [];
    result.forEach(x=>{
      ret.push(x.model as (T|null));
    });
    return ret;
  }
}
