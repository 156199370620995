
  import {Component, Prop, Vue} from "vue-property-decorator";
  import Utility from "@/plugins/utility";
  import ImageModel from "@/models/image";

  @Component
  export default class UploadComplete extends Vue
  {
    name: string = "upload-complete";

    @Prop()
    isShow!: boolean;
    @Prop()
    isComplete!: boolean;
    @Prop()
    image!: ImageModel;

    isOpen: boolean = this.isShow;

    onClickShare()
    {
      Utility.openTweetWindow(this.image);
    }

    onClickComplete()
    {
      if(this.isComplete)
      {
        this.$emit("end-upload-complete");

      }
    }

  }
