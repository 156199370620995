import { Vue, Component } from "vue-property-decorator";
import UserModel from '@/models/user';
import {Location, Route} from 'vue-router';
import Utility from '@/plugins/utility';

type State = {
  isInfoRead: boolean;
  myUser: UserModel | null;
  isLoginDialogOpen: boolean;
  isDrawerOpen: boolean;
  isSnackbarOpen: boolean;
  snackbarText: string;
}

const state = Vue.observable<State>({
  isInfoRead: false,
  myUser: null,
  isLoginDialogOpen: false,
  isDrawerOpen: false,
  isSnackbarOpen: false,
  snackbarText: '',
});

@Component
export default class StoreMixin extends Vue
{
  get isInfoRead() { return state.isInfoRead; }
  get isLogin(){ return state.myUser != null; }
  get myUserModel() { return state.myUser as UserModel; }
  get isLoginDialogOpen(){ return state.isLoginDialogOpen; }
  get isDrawerOpen(){ return state.isDrawerOpen; }
  get isSnackbarOpen(){ return state.isSnackbarOpen; }
  get snackbarText(){ return state.snackbarText; }

  set isDrawerOpen(v: boolean){ state.isDrawerOpen = v; }
  set isSnackbarOpen(v: boolean){ state.isSnackbarOpen = v; }

  /**
   * 今日投稿したか
   */
  get isTodayPosted()
  {
    if(state.myUser == null) return true;
    const posted = Utility.convertDate(state.myUser.postedAt);
    return Utility.isToday(posted);
  }

  updateIsInfoRead(v: boolean)
  {
    state.isInfoRead = v;
  }

  updateMyUser(user: UserModel | null)
  {
    state.myUser = user;
  }

  setIsLoginDialogOpen(v: boolean)
  {
    state.isLoginDialogOpen = v;
  }

  openSnackbar(str: string)
  {
    state.isSnackbarOpen = true;
    state.snackbarText = str;
  }

  async jumpToRegister()
  {
    return this.jumpTo('/register');
  }

  async jumpToHome()
  {
    return this.jumpTo('/');
  }

  async jumpToUser(uid: string)
  {
    return this.jumpTo(`/user/${uid}`);
  }

  async jumpToUserImage(uid: string, image?: string, date?: string)
  {
    var path = `/user/${uid}`;
    if(image)
    {
      path += `/image/${image}`;
    }
    if(date)
    {
      path += `/date/${date}`;
    }
    return this.jumpTo(path);
  }

  /**
   * pathへ飛ぶ
   * @param name
   * @param params
   */
  async jumpTo(path: string): Promise<Route | null>
  {
    if(this.$route.path == path)
    {
      return null;
    }
    return new Promise<Route>((resolve, reject) => {
      this.$router.push(path)
        .then(route=>{
          resolve(route);
        })
        .catch(e=>{
          if('NavigationDuplicated' != e.name)
          {
            console.error(e);
          }
        });

    });
  }

  async overrideImage(url: string)
  {
    document.querySelector("meta[property='og:image']")!.setAttribute('content', url);
  }

  async overrideDescription(desc: string)
  {
    document.querySelector("meta[property='og:description']")!.setAttribute('content', desc);
  }
}
