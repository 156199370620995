
import {Component, Mixins, Watch} from 'vue-property-decorator';
import StoreMixin from '@/store-mixin';
import AppStore from '@/store';
import ImageModel from '@/models/image';
import UserModel from '@/models/user';
import Utility from '@/plugins/utility';
import ImageComments from '@/components/ImageComments.vue';
import SmallUserProfile from '@/components/SmallUserProfile.vue';
import {Route} from 'vue-router';

@Component({
  components: {
    SmallUserProfile,
    ImageComments,
  }
})

export default class UserImage extends Mixins(StoreMixin) {
  name: string = "user-image";
  model: ImageModel | null = null;
  user: UserModel | null = null;
  date: Date | null = null;
  uid: string = '';

  isNoImage: boolean = false;

  get comment() {
    if (this.model == null) return undefined;
    else return this.model.comment;
  }

  get postedDate() {
    if (this.date == null) return '';
    return Utility.getFormattedDate(this.date);
  }

  @Watch('$route', {immediate: true, deep: true})
  async onRouteChange(to: Route) {
    const imageId = this.$route.params.id;
    this.uid = this.$route.params.uid;
    const dateStr = this.$route.params.date;

    if ((dateStr == undefined && imageId == undefined) || this.uid == undefined) {
      console.error('Invalid route');
      await this.$router.push({path: '/'});
      return;
    }

    if (imageId) {
      this.model = await AppStore.I.getImageModel(imageId, this.uid);
      if (this.model == null) {
        console.error('Image not found', imageId, this.uid, this.$route.params);
        await this.$router.push({path: '/'});
        return;
      }
      this.user = Utility.snippetToUserModel(this.uid, this.model.userSnippets);
      this.date = this.model.createdAt as Date;
      this.isNoImage = false;
    } else if (dateStr) {
      this.date = new Date();
      this.date.setFullYear(parseInt(dateStr.slice(0, 4)));
      this.date.setMonth(parseInt(dateStr.slice(4, 6)));
      this.date.setDate(parseInt(dateStr.slice(6, 8)));
      this.model = await AppStore.I.getImageModelByDay(this.uid, this.date);
      if (this.model) {
        this.user = Utility.snippetToUserModel(this.uid, this.model.userSnippets);
        this.isNoImage = false;
      } else {
        this.isNoImage = true;
      }
    }
  }

  async back() {
    if (!this.date) return;

    const next = Utility.getYesterday(this.date);
    await this.jumpToUserImage(this.uid, undefined, next.getFullYear() + next.getMonth().toString().padStart(2, '0') + next.getDate().toString().padStart(2, '0'));
  }

  async forward() {
    if (!this.date) return;

    const next = Utility.getTomorrow(this.date);
    await this.jumpToUserImage(this.uid, undefined, next.getFullYear() + next.getMonth().toString().padStart(2, '0') + next.getDate().toString().padStart(2, '0'));
  }

  onClickShare() {
    if (!this.model) return;

    Utility.openTweetWindow(this.model);
  }
}
