import firebase from 'firebase';

export default class CloudStorageClient
{
  private storageRef: firebase.storage.Reference;
  private cacheType: string = 'public, max-age=31536000';

  constructor(storage: firebase.storage.Storage)
  {
    this.storageRef = storage.ref();
  }

  /**
   * ファイルをアップロードします
   * @param name
   * @param data
   * @param contentType
   */
  upload(name: string, data: ArrayBuffer, contentType: string): firebase.storage.UploadTask
  {
    const ref = this.storageRef.child(name);
    const metadata: firebase.storage.UploadMetadata = {
      contentType: contentType,
      cacheControl: this.cacheType,
    };
    return ref.put(data, metadata);
  }

  /**
   * URLを返します
   * @param name
   */
  getDownloadUrl(name: string): Promise<string>
  {
    const ref = this.storageRef.child(name);
    return ref.getDownloadURL();
  }

  /**
   * 指定されたパスにあるイメージを削除します
   * @param name
   */
  delete(name: string): Promise<void>
  {
    const ref = this.storageRef.child(name);
    return ref.delete();
  }

}
