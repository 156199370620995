import 'firebase/auth';
import 'firebase/firestore';
import * as firebase from 'firebase';

export default class FirebaseClient {
  private static client: FirebaseClient;

  public readonly firebaseApp: firebase.app.App;
  public readonly fireStore: firebase.firestore.Firestore;
  public readonly cloudStorage: firebase.storage.Storage;

  constructor() {
    const config = {
      apiKey: process.env.VUE_APP_API_KEY,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_DATABASE_URL,
      projectId: process.env.VUE_APP_PROJECT_ID,
      storageBucket: process.env.VUE_APP_STORAGE,
      messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_APP_ID,
      measurementId: process.env.VUE_APP_MEASUREMENT_ID
    };
    this.firebaseApp = firebase.initializeApp(config);
    this.fireStore = this.firebaseApp.firestore();
    this.cloudStorage = this.firebaseApp.storage();
  }

  public static get Client(): FirebaseClient {
    if (!this.client) {
      this.client = new FirebaseClient();
    }
    return this.client;
  }
}
