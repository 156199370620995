
  import { Component, Prop, Vue } from "vue-property-decorator";
  import Utility from '@/plugins/utility';
  import AchieveInfo from '@/models/achieve-info';

  @Component
  export default class UserAchieve extends Vue
  {
    name:string = "user-achieve";

    @Prop()
    achieveFlags!: number;

    getAchieves(): AchieveInfo[]
    {
      const types = Utility.getAchieves(this.achieveFlags);
      const info = AchieveInfo.keepOnAchieves();
      return info.filter(y => types.includes(y.type));
    }

  }
