import {firestore} from "firebase";
import Timestamp = firestore.Timestamp;

import ImageModel, {UserSnippet} from '@/models/image';
import {AchieveType} from '@/models/achieve-info';
import UserModel from '@/models/user';

export default class Utility
{
  public static base64ToBlob(base64: string): Blob
  {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }

    let blob;
    try {
      blob = new Blob([buffer.buffer], {
        type: 'image/png'
      });
    } catch (e) {
      console.log('error occurred : ' + (e as any).toString());
      return new Blob();
    }
    return blob;
  }

  public static base64ToBuffer(base64: string): ArrayBuffer
  {
    const binary_string = window.atob(base64.replace(/^.*,/, ''));
    const len = binary_string.length;
    const bytes = new Uint8Array( len );
    for (let i = 0; i < len; i++)
    {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes.buffer;
  }

  public static resize (file:Blob, maxsize:number, afterAction:(dataUrl:string)=>void)
  {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const image = new Image();

    canvas.width = 0;
    canvas.height = 0;

    image.src = URL.createObjectURL(file);
    image.onload = ()=> {

      let width, height;
      if(image.width > maxsize || image.height > maxsize)
      {
        if(image.width > image.height)
        {
          const ratio = image.height/image.width;
          width = maxsize;
          height = maxsize * ratio;
        }
        else
        {
          const ratio = image.width/image.height;
          width = maxsize * ratio;
          height = maxsize;
        }
      }
      else
      {
        width = image.width;
        height = image.height;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, 0, 0, width, height);

      // afterAction(canvas.toDataURL(file.type, 0.7))
      afterAction(canvas.toDataURL('image/jpeg', 0.8))
    }
  }

  public static getImageSize(breakpoint: string): number
  {
    switch (breakpoint) {
      case 'xs': return 400;
      case 'sm': return 500;
      case 'md': return 600;
      case 'lg': return 800;
      case 'xl': return 1000;
    }
    return 600;
  }

  public static getAchieves(achieveFlags: number): number[]
  {
    const ret: AchieveType[] = [];
    Object.entries(AchieveType).forEach(([key, value]) => {
      if (achieveFlags & Number(key)) {
        ret.push(Number(key));
      }
    });
    return ret;
  }

  static openTweetWindow(image: ImageModel)
  {
    const url = location.origin
      + "/user/" + image.userId
      + "/image/" + image.id;
    const user = image.userSnippets;
    const text = user.name + " "
      + user.count + "日目 ";

    const content = "https://twitter.com/intent/tweet?text=" + encodeURI(text) + encodeURIComponent('#picmeter') + " " + encodeURIComponent(url);

    window.open(
      content,
      "name",
      "width=600,height=400,resizable,scrollbars=yes,status=1");
  }

  static getFileExtends(name: string) : string
  {
    const pos = name.lastIndexOf('.');
    if (pos === -1) return '';
    return name.slice(pos + 1);
  }

  static convertDate(dateOrTimestamp: Date | Timestamp): Date
  {
    if(dateOrTimestamp instanceof Date)
    {
      return dateOrTimestamp as Date;
    }
    const timestamp = (dateOrTimestamp as Timestamp);
    return timestamp.toDate();
  }

  static isToday(date: Date): boolean
  {
    const now = new Date();
    return now.getFullYear() == date.getFullYear() &&
      now.getMonth() == date.getMonth() &&
      now.getDate() == date.getDate();
  }

  /**
   * 継続中か
   * @param user
   */
  static isContinue(postedAt: Date | Timestamp, today: Date = new Date()): boolean
  {
    const latest = this.convertDate(postedAt);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const isYesterday = yesterday.getFullYear() == latest.getFullYear() &&
      yesterday.getMonth() == latest.getMonth() &&
      yesterday.getDate() == latest.getDate();
    const isToday = today.getFullYear() == latest.getFullYear() &&
      today.getMonth() == latest.getMonth() &&
      today.getDate() == latest.getDate();
    return isYesterday || isToday;
  }

  /**
   * スニペットをモデルに変換
   * @param id
   * @param snippet
   */
  static snippetToUserModel(id: string, snippet: UserSnippet): UserModel
  {
    const ret = new UserModel(null);
    ret.id = id;
    ret.count = snippet.count;
    ret.name = snippet.name;
    ret.profileImage = snippet.profileImage;
    ret.achieveFlags = snippet.achieveFlags;
    return ret;
  }

  static getFormattedTime(dateOrTimeStamp: Date | Timestamp): string
  {
    const date = this.convertDate(dateOrTimeStamp);
    return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
  }

  static getFormattedDate(dateOrTimeStamp: (Date | Timestamp) | Date): string
  {
    const date = this.convertDate(dateOrTimeStamp);
    return (
      date.getFullYear() +
      "年 " +
      (date.getMonth() + 1) +
      "月 " +
      date.getDate() +
      "日"
    );
  }

  static getYesterday(dateOrTimestamp: (Date | Timestamp) | Date): Date
  {
    const date = this.convertDate(dateOrTimestamp);
    date.setDate(date.getDate() - 1);
    return date;
  }

  static getTomorrow(dateOrTimestamp: (Date | Timestamp) | Date): Date
  {
    const date = this.convertDate(dateOrTimestamp);
    date.setDate(date.getDate() + 1);
    return date;
  }
}
