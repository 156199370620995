import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;

export default class UserModel
{
    id: string;

    profileImage: string | null;
    name: string | null;
    count: number;
    bestCount: number;
    postCount: number;
    achieveFlags: number;
    description: string = '';
    twitterId: string = '';
    postedAt: Date | Timestamp;
    updatedAt: Date | Timestamp;
    createdAt: Date | Timestamp;

    constructor(user: firebase.UserInfo | null)
    {
        this.id = user == null ? '' : user.uid;
        this.profileImage = user == null ? '' : user.photoURL;
        this.name = user == null ? '' : user.displayName;
        this.count = 0;
        this.bestCount = 0;
        this.postCount = 0;
        this.postedAt = new Date('05 October 2011 14:48 UTC');
        this.description = '';
        this.twitterId = '';
        this.updatedAt = new Date();
        this.createdAt = new Date();
        this.achieveFlags = 0;
    }
}
