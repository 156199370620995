
  import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
  import UserAchieve from '@/components/UserAchieve.vue';
  import StoreMixin from '@/store-mixin';
  import Logo from '@/components/Logo.vue';
  import AppStore from '@/store';

  @Component({
    components: {
      UserAchieve,
      Logo,
    }
  })

  export default class LoginDialog extends Mixins(StoreMixin)
  {
    name:string = "login-dialog";

    onClose()
    {
      this.setIsLoginDialogOpen(false);
    }

    async loginWithTwitter()
    {
      const res = await AppStore.I.signInWithTwitter();

      if(res === null)
      {
        const route = this.jumpToRegister();
        this.openSnackbar('ログインされていません。ご登録をお願いします');
      }
      else
      {
        this.updateMyUser(res);

        this.openSnackbar('ログインしました。');
      }

      this.setIsLoginDialogOpen(false);
    }

    async loginWithGoogle()
    {
      const res = await AppStore.I.signInWithGoogle();

      if(res === null)
      {
        const route = this.jumpToRegister();
        this.openSnackbar('ログインされていません。ご登録をお願いします');
      }
      else
      {
        this.updateMyUser(res);
        this.openSnackbar('ログインしました。');
      }

      this.setIsLoginDialogOpen(false);
    }
  }
