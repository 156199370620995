
  import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
  import StoreMixin from '@/store-mixin';
  import ImageModel, {UserComment} from '@/models/image';
  import AppStore from '@/store';
  import Timestamp = firebase.firestore.Timestamp;
  import Utility from '@/plugins/utility';
  import firebase from 'firebase';

  @Component
  export default class ImageComments extends Mixins(StoreMixin)
  {
    name: string = 'image-comments';
    editComment: string = '';
    comments: UserComment[] = [];

    @Prop() image!: ImageModel;

    readonly CommentRule: any = [function (v: any) {
      if(undefined == v || undefined == v.length) return true;
      return v.length <= 50 || '50文字以内で入力してください';
    }];

    get commentOuterIcon()
    {
      if(this.editComment == '' || !this.editComment) return '';
      if(this.editComment.length > 50) return '';
      return 'send';
    }

    async onClickSend()
    {
      if(!this.editComment) return;

      const result = await AppStore.I.addComment(this.image, this.myUserModel, this.editComment);
      this.openSnackbar('コメントを投稿しました');
      this.editComment = '';
    }

    async onClickName(userId: string)
    {
      await this.jumpToUser(userId);
    }

    convertDateFormat(dateOrTimestamp: Date | Timestamp): string
    {
      const date = Utility.convertDate(dateOrTimestamp);
      return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日 " + date.getHours() + ":" + date.getMinutes();
    }
  }
