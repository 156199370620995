
  import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
  import ImageModel from "@/models/image";
  import Utility from "@/plugins/utility";
  import ModalDialog from "@/components/ModalDialog.vue";
  import UserAchieve from "@/components/UserAchieve.vue";
  import StoreMixin from '@/store-mixin';
  import AppStore from '@/store';
  import UserModel from '@/models/user';
  import YesNoDialog from "@/components/YesNoDialog.vue";
  import Timestamp = firebase.firestore.Timestamp;
  import ImageComments from '@/components/ImageComments.vue';
  import firebase from 'firebase';
  import SmallUserProfile from '@/components/SmallUserProfile.vue';

  @Component({
    components: {
      ImageComments,
      YesNoDialog,
      UserAchieve,
      ModalDialog,
      SmallUserProfile,
    }
  })
  export default class ImageDialog extends Mixins(StoreMixin) {
    name: string = "image-dialog";

    @Prop()
    isOpenImage!: boolean;

    @Prop()
    selectedImage!: ImageModel;

    isOpenDelete: boolean = false;
    isDeleting: boolean = false;
    user: UserModel | null = null;

    @Emit('close-image')
    onCloseImage()
    {

    }

    @Emit('on-delete')
    onDelete(user: UserModel)
    {
      this.onCloseImage();
    }

    get isMyImage(): boolean
    {
      if(!this.isLogin) return false;
      return this.myUserModel.id == this.selectedImage.userId;
    }

    get dialogTitle()
    {
      return Utility.getFormattedDate(this.selectedImage.createdAt);
    }

    onClickShare()
    {
      Utility.openTweetWindow(this.selectedImage);
    }

    onClickImage()
    {
      this.jumpToUserImage(this.selectedImage.userId, this.selectedImage.id);
    }

    async onSelectDelete(isDelete: boolean)
    {
      if(isDelete)
      {
        this.isDeleting = true;
        await this.deleteImage();
        this.onDelete(this.myUserModel);
      }
      this.isOpenDelete = false;
    }

    onClickDelete()
    {
      this.isDeleting = false;
      this.isOpenDelete = true;
    }

    async deleteImage(): Promise<void>
    {
      return AppStore.I.deleteImage(this.selectedImage);
    }

    get getTimeFormat()
    {
      return Utility.getFormattedTime(this.selectedImage.createdAt);
    }

    @Watch('selectedImage', {immediate: true})
    onChange(to: ImageModel)
    {
      this.user = Utility.snippetToUserModel(to.userId, to.userSnippets);
    }
  }
