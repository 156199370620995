/**
 * バージョン
 * X.X.Xのフォーマット
 */
export default class VersionControl
{
  majorVersion!: number;
  minerVersion!: number;
  fixedVersion!: number;
  versionString!: string;

  constructor(version: string = '0.0.0')
  {
    const versions = version.split('.');
    if(versions.length != 3)
    {
      throw new Error("invalid version format. : " + version);
    }
    this.majorVersion = parseInt(versions[0]);
    this.minerVersion = parseInt(versions[1]);
    this.fixedVersion = parseInt(versions[2]);
    this.versionString = version;
  }

  static Create(version: string | undefined) : VersionControl
  {
    if(version == undefined)
      return new VersionControl();

    return new VersionControl(version);
  }

  /**
   * 引数のversionのほうが高いか
   * @param version
   */
  isHigher(version: VersionControl): boolean
  {
    if(version.majorVersion > this.majorVersion)
    {
      return true;
    }
    else if (version.majorVersion == this.majorVersion)
    {
      if(version.minerVersion > this.minerVersion)
      {
        return true;
      }
      else if(version.minerVersion == this.minerVersion)
      {
        if(version.fixedVersion >= this.fixedVersion)
        {
          return true;
        }
      }
    }

    return false;
  }


}
