
  import { Component, Prop, Vue } from "vue-property-decorator";
  import Utility from '@/plugins/utility';

  @Component
  export default class ModalDialog extends Vue
  {
    name: string = "modal-dialog"

    @Prop()
    isOpen!: boolean;

    @Prop()
    width!: number;

    private close()
    {
      this.$emit('on-close');
    }
  }
