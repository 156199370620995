
  import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
  import ImageModel from "@/models/image";
  import StoreMixin from '@/store-mixin';

  @Component
  export default class ImageCard extends Mixins(StoreMixin) {
    name: string = "image-card";
    url: string = "";

    @Prop()
    model!: ImageModel;

    private onClickCard(model: ImageModel) {
      this.$emit("on-click", model);
    }

    onClickChip()
    {
      this.jumpToUser(this.model.userId);
    }
  }
