/**
 * LocalStorageのラッパー
 */
export default class AppStorage
{
  private _localStorage!: Storage;

  constructor()
  {
    this._localStorage = localStorage;
  }

  public getFromLocal(key: string): string | null
  {
    return this._localStorage.getItem(key);
  }

  public setFromLocal(key: string, value: string)
  {
    try
    {
      this._localStorage.setItem(key, value);
    }
    catch
    {
      throw new Error("LocalStorage is invalid");
    }
  }
}
