
  import {Component, Emit, Mixins, Prop, Vue} from "vue-property-decorator";
  import StoreMixin from '@/store-mixin';

  @Component({
    components: {
    }
  })

  export default class YesNoDialog extends Mixins(StoreMixin)
  {
    name:string = "yes-no-dialog";

    @Prop()
    isOpen!:boolean;

    @Prop()
    isDisable!:boolean;

    @Emit('on-close')
    onClose()
    {}

    @Emit('on-select')
    onSelect(isYes: boolean)
    {}

  }
