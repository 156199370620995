
  import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
  import Distortion from "@/components/Distortion.vue";
  import TodayImageList from '@/components/TodayImageList.vue';
  import StoreMixin from '@/store-mixin';
  import Uploader from '@/components/Uploader.vue';
  import Adsense from '@/components/Adsense.vue';

  @Component({
    components: {
      Distortion,
      TodayImageList,
      Uploader,
      Adsense,
    }
  })

  /**
   * ホーム画面
   */
  export default class Home extends Mixins(StoreMixin)
  {
    $refs!:{
      todayImageList: TodayImageList
    };

    updateUser(): void
    {
      this.$refs.todayImageList.updateImage();
    }
  }
