
  import { Component, Prop, Vue } from "vue-property-decorator";
  import Description from '@/components/Description.vue';

  @Component({
    components: {
      Description,
    }
  })

  /**
   * 登録画面
   */
  export default class Terms extends Vue
  {
    name: string = "terms"

  }
