
  import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
  import AppStore from "@/store";
  import StoreMixin from "@/store-mixin";

  @Component
  export default class Distortion extends Mixins(StoreMixin)
  {
    name: string = "distortion";

    async mounted()
    {
    }

    async onClickStart()
    {
      if(null !== this.myUserModel)
      {
        await this.jumpToUser(this.myUserModel.id);
      }
      else
      {
        await this.jumpToRegister();
      }
    }
  };
