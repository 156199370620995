
  import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
  import StoreMixin from '@/store-mixin';
  import UserModel from '@/models/user';
  import {mdiTwitter} from '@mdi/js';
  import Utility from '@/plugins/utility';
  import AppStore from '@/store';

  @Component({
    components: {
    }
  })

  export default class ProfileEdit extends Mixins(StoreMixin)
  {
    name: string = 'profile-edit';
    myUser: UserModel = new UserModel(null);

    editName: string = '';
    iconSource: string = '';
    iconBuffer: ArrayBuffer | null = null;
    isChangeIcon: boolean = false;
    editDescription: string = '';
    editTwitter: string = '';
    isClickUpdate: boolean = false;
    isDescriptionValid: boolean = true;

    readonly TwitterIcon: string = mdiTwitter;
    readonly IconSize: number = 120;
    readonly ProfileRule: any = [function (v: any) {
      if(undefined == v || undefined == v.length) return true;
      return v.length <= 100 || '100文字以内で入力してください';
    }];

    async mounted()
    {
      if(!this.isLogin)
      {
        const route = this.jumpToHome();
        return;
      }

      this.myUser = this.myUserModel;
      this.editName = this.myUser.name as string;
      this.iconSource = this.myUser.profileImage as string;
      this.editDescription = this.myUser.description;
      this.editTwitter = this.myUser.twitterId;
    }

    get changeDisable(): boolean
    {
      return (this.isNotChange
        && !this.isChangeIcon)
        || this.isClickUpdate;
    }

    private get isNotChange()
    {
      return this.myUser.name == this.editName as string
        && (this.myUser.description == this.editDescription || this.editDescription == '' || !this.isDescriptionValid)
        && this.myUser.twitterId == this.editTwitter;
    }

    onClickIcon(): void
    {
      (this.$refs.image as HTMLImageElement).click();
    }

    onSelectFile(e: any): void
    {
      const files = e.target.files;
      const file = files[0];

      if(null != file)
      {
        Utility.resize(file, this.IconSize, (dataUrl: string)=>
        {
          this.iconSource = dataUrl;
          this.iconBuffer = Utility.base64ToBuffer(dataUrl);
          this.isChangeIcon = true;
        });
      }
    }

    async onClickChange()
    {
      this.isClickUpdate = true;

      if(null != this.iconBuffer)
      {
        const uploadTask = await AppStore.I.uploadUserIcon(this.iconBuffer, this.myUser.id);
        let url = await uploadTask.ref.getDownloadURL();
        if(url.includes('&token='))
        {
          url = url.split('&token=')[0];
        }
        this.myUser.profileImage = url;
      }

      this.myUser.name = this.editName;
      if(this.editDescription)
      {
        this.myUser.description = this.editDescription;
      }
      else
      {
        this.myUser.description = '';
      }
      if(this.editTwitter)
      {
        this.myUser.twitterId = this.editTwitter;
      }
      else
      {
        this.myUser.twitterId = '';
      }
      this.myUser.updatedAt = new Date();

      await AppStore.I.setUserModel(this.myUser);

      await this.jumpToUser(this.myUser.id);
      this.openSnackbar('プロフィールを更新しました。');
    }

    @Watch('editDescription')
    onChangeDescription(to: string)
    {
      if(undefined == to || undefined == to.length) return ;
      this.isDescriptionValid = 0 <= to.length && to.length <= 100;
    }
  }
