
  import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
  import HeaderItem from './components/Header.vue';
  import StoreMixin from "@/store-mixin";
  import AppStore from '@/store';
  import {mdiInformationOutline} from '@mdi/js';

  @Component({
    components: {
      HeaderItem,
    }
  })

  export default class App extends Mixins(StoreMixin)
  {
    name : string ="app";
    infoIcon: string = mdiInformationOutline;

    async created()
    {
      const firebaseUser = await AppStore.I.getFirebaseUser();
      if(null == firebaseUser)
        return ;

      const user = await AppStore.I.getUserModel(firebaseUser.uid);
      this.updateMyUser(user);
    }

    async onClickInfo()
    {
      const route = this.jumpTo('info');
    }

    async onClickUserList()
    {
      const route = this.jumpTo('user-list');
    }

    async onClickLogout()
    {
      await AppStore.I.signOut();
      this.openSnackbar('ログアウトしました。');
      this.updateMyUser(null);
    }

    async onClickAvatar()
    {
      await this.jumpToUser(this.myUserModel.id);
    }

    async onClickEditProfile()
    {
      const route = this.jumpTo('profile-edit');
    }
  }
