
  import {Component, Vue} from 'vue-property-decorator';
  import AppStore from '@/store';
  import UserModel from '@/models/user';
  import {DocumentModel} from '@/plugins/firestore';
  import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
  import firebase from 'firebase';
  import Utility from '@/plugins/utility';
import StoreMixin from '@/store-mixin';

  @Component({
    components: {
    }
  })

  /**
   * ユーザー一覧画面
   */
  export default class UserList extends StoreMixin
  {
    tabRef: number = 0;
    name: string = 'user-list';
    users: DocumentModel<UserModel>[] = [];
    isMoreActive: boolean = true;
    tabs: { [key: string]: string } = {
      '継続中': 'continue',
      '累計投稿枚数順': 'postCount',
      '最高継続日数順': 'bestCount',
    };
    currentParam: string = 'continue';

    count: number = 20;

    async mounted()
    {
      const tab = this.$route.query.tab;
      if (tab)
      {
        this.currentParam = tab as string;
      }
      this.onClickTab(this.currentParam);
      this.tabRef = Object.values(this.tabs).indexOf(this.currentParam);
    }

    async onClickNext()
    {
      if(this.users.length === 0) return ;

      const add = await AppStore.I.getUsers(this.count, this.lastSnapshot, this.currentParam);
      this.users = this.users.concat(add);

      if(add.length < this.count)
      {
        this.isMoreActive = false;
      }
    }

    async onClickUser(user: UserModel | null)
    {
      if(null == user) return ;
      const route = await this.jumpToUser(user.id);
    }

    isContinue(user: UserModel | null)
    {
      if (null == user) return false;
      return Utility.isContinue(user.postedAt, new Date());
    }

    async onClickTab(tab: string)
    {
      this.currentParam = tab
      this.users = [];
      this.isMoreActive = true;

      if (tab === 'continue')
      {
        var add = await AppStore.I.getContinueUsers(this.count, null, new Date());
        this.users = add;
        if(add.length < this.count)
        {
          this.isMoreActive = false;
        }
      } else {
        var add = await AppStore.I.getUsers(this.count, null,tab); 
        this.users = add;
        if(add.length < this.count)
        {
          this.isMoreActive = false;
        }
      }

      this.$router.push({ query: { tab: tab } });
    }

    get lastSnapshot(): DocumentSnapshot
    {
      return this.users[this.users.length - 1].snapshot;
    }

    itemListSubtitle(user: UserModel | null): string
    {
      if(null == user) return '';
      if(this.currentParam === 'continue')
      {
        return `継続${user.count}日目/累計${user.postCount}枚/最高${user.bestCount}日`;
      }

      return `累計${user.postCount}枚/最高${user.bestCount}日`;
    }
  }
