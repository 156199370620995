
  import {Component, Emit, Mixins, Prop, Vue} from "vue-property-decorator";
  import ModalDialog from "@/components/ModalDialog.vue";
  import Utility from "@/plugins/utility";
  import Loading from "@/components/Loading.vue";
  import ImageModel, {UserComment} from '@/models/image';
  import UserModel from "@/models/user";
  import UploadComplete from "@/components/UploadComplete.vue";
  import StoreMixin from '@/store-mixin';
  import AppStore from '@/store';
  import {AchieveType} from '@/models/achieve-info';

  @Component({
    components: {
      UploadComplete,
      ModalDialog,
      Loading,
    }
  })

  export default class Uploader extends Mixins(StoreMixin)
  {
    name:string = "Uploader";

    currentImage: ImageModel | null = null;

    // uploader
    isUploaderOpen: boolean = false;
    isSelected: boolean = false;
    todayStr: string = "";

    // image
    imageName: string = '';
    imageFile: File | null = null;
    imageBuffer: ArrayBuffer | null = null;
    imageBase64: string = "";
    imageWidth: number = 0;
    editComment: string = "";

    // loading
    isLoading: boolean = false;
    isUploadComplete: boolean = false;
    isUploadCompleteShow: boolean = false;
    loadingTitle: string = '';
    extTitle: string = '';
    achieveText: string = '';

    readonly CommentRule: any = [function (v: any) {
      if(undefined == v || undefined == v.length) return true;
      return v.length <= 50 || '50文字以内で入力してください';
    }];

    get isUploadValid()
    {
      return this.isSelected && (!this.editComment || this.editComment.length <= 50);
    }

    get uploadCompleteTitle(): string
    {
      if(!this.isLogin) return "";
      return this.isUploadComplete ? this.myUserModel.count + "日目" : "アップロード中";
    }

    selectFile()
    {
      (this.$refs.image as HTMLImageElement).click();
    }

    onSelectFile(e: any)
    {
      const maxsize = 1000;
      const files = e.target.files;
      const file = files[0];

      if(file !== undefined)
      {
        this.imageName = file.name;
        if(this.imageName.lastIndexOf('.') <= 0)
        {
          return;
        }

        Utility.resize(file, maxsize, (dataUrl: string)=>
        {
          this.imageFile = file;
          this.imageBase64 = dataUrl;
          this.imageBuffer = Utility.base64ToBuffer(dataUrl);
          this.isSelected = true;
        });
      }
      else
      {
        this.imageName = '';
        this.imageFile = null;
        this.imageBuffer = null;

        this.isSelected = false;
      }
    }

    private revertImage()
    {
      this.imageBase64 = '';
      this.imageName = '';
      this.imageFile = null;
      this.imageBuffer = null;

      this.isSelected = false;
    }

    get imgSize(): number
    {
      return Utility.getImageSize(this.$vuetify.breakpoint.name) - 100;
    }

    async upload()
    {
      if(null == this.imageFile || null == this.imageBuffer)
      {
        return;
      }

      if(null == this.myUserModel)
      {
        return;
      }

      if(!this.checkUploadPossible(this.myUserModel))
      {
        this.openSnackbar('本日はアップロード済みです。');
        return;
      }

      this.isLoading = true;
      this.loadingTitle = 'uploading...';

      const myUserId = this.myUserModel.id;
      const now = new Date();
      const preCount = this.myUserModel.count;

      // upload
      const res = await AppStore.I.uploadDailyImage(this.imageFile.name,
        now,
        this.imageBuffer,
        'image/jpeg',
        myUserId
      );
      const rawUrl: string = await res.ref.getDownloadURL();
      let url = rawUrl;
      if(rawUrl.includes('&token='))
      {
        url = rawUrl.split('&token=')[0];
      }
      const fullPath = res.ref.fullPath;

      // update user model
      const {isReset, isUpdate, info, count, isBest} = await AppStore.I.updatePostCount(this.myUserModel);

      let text: string = "";
      if(preCount == 0)
      {
        text = "はじめての投稿です。これから頑張りましょう";
      }
      else if(isReset)
      {
        text = "日付がリセットされました。";
      }
      else if(isUpdate)
      {
        text = count + "日目の更新です。";
        if(isBest)
        {
          text += "最高記録更新中です。"
        }
        if(info.type != AchieveType.None)
        {
          this.achieveText = "★ " + info.tooltip;
        }
      }
      this.extTitle = text;

      // set image model
      this.currentImage = new ImageModel((res.metadata.generation as string), url, fullPath, this.myUserModel, this.editComment);
      await AppStore.I.insertImage(this.currentImage);

      this.endUpload(this.myUserModel);
    }

    @Emit('end-upload')
    endUpload(user: UserModel)
    {
      this.isUploadComplete = true;
    }

    private openUploader()
    {
      this.isUploaderOpen = true;

      const today = new Date();
      this.todayStr = (today.getMonth() + 1) + "月" + today.getDate() + "日";
    }

    public onCloseUploader()
    {
      this.isUploaderOpen = false;
      this.isUploadComplete = false;
      this.revertImage();
    }

    public onEndUploaderComplete()
    {
      this.isLoading = false;
      this.onCloseUploader();
    }

    private checkUploadPossible(user: UserModel): boolean
    {
      const todayStart = new Date();
      todayStart.setHours(0);
      todayStart.setMinutes(0);
      todayStart.setSeconds(0);
      const posted = Utility.convertDate(user.postedAt);
      return todayStart.getTime() >= posted.getTime();
    }
  }
