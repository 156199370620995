
  import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
  import Description from '@/components/Description.vue';
  import AppStore from '@/store';
  import StoreMixin from '@/store-mixin';

  @Component({
    components: {
      Description,
    }
  })

  /**
   * 登録画面
   */
  export default class Register extends Mixins(StoreMixin)
  {
    public async registerWithGoogle()
    {
      const user = await AppStore.I.registerWithGoogle();
      this.updateMyUser(user);
      await this.$router.push({path: "user", query: {uid:user.id}});
    }

    public async registerWithTwitter()
    {
      const user = await AppStore.I.registerWithTwitter();
      this.updateMyUser(user);
      await this.jumpToUser(user.id);
    }
  }
