
import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import StoreMixin from "../store-mixin";
import ImageModel from '@/models/image';
import UserAchieve from '@/components/UserAchieve.vue';
import UserModel from '@/models/user';
import Utility from '@/plugins/utility';
import UserIcon from "@/components/UserIcon.vue";

@Component({
  components: {
    UserIcon,
    UserAchieve,
  }
})

export default class SmallUserProfile extends Mixins(StoreMixin) {
  name: string = "small-user-profile";

  // エラー回避
  userProfile: string = 'dummy';

  @Prop()
  user!: UserModel;

  @Prop()
  image!: ImageModel;

  async onClickName() {
    const route = await this.$router
      .push('/user/' + this.image.userId)
      .catch(e => {
        if (e.name == "NavigationDuplicated") {
          this.onCloseImage();
        }
      });
  }

  get iconSize() {
    if (this.$vuetify.breakpoint.xs) {
      return 55;
    }
    return 70;
  }

  @Emit('close-image')
  onCloseImage() {
  }

  updateProfileSafe() {
    if (this.user.profileImage == null) return;

    this.userProfile = this.user.profileImage;
  }

  @Watch('user', {immediate: true})
  onChangeImage(to: ImageModel) {
    if (this.user.profileImage == null) return;

    this.userProfile = this.user.profileImage.replace('_normal', '_400x400');
  }

  get imageDate() {
    return Utility.getFormattedDate(this.image.createdAt) + Utility.getFormattedTime(this.image.createdAt);
  }
}
