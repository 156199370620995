
    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component
    export default class Loading extends Vue
    {
        name: string = "loading";

        @Prop()
        private isShow: boolean = false;
    }
