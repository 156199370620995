import firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import UserModel from "@/models/user";

export class UserComment
{
  userId: string;
  name: string;
  profileImage: string;
  comment: string;
  createdAt: Date | Timestamp;

  constructor(user: UserModel, comment: string)
  {
    this.userId = user.id;
    this.name = user.name as string;
    this.profileImage = user.profileImage as string;
    this.comment = comment;
    this.createdAt = new Date();
  }
}

export interface UserSnippet
{
  name: string;
  profileImage: string;
  count: number;
  achieveFlags: number;
}

export default class ImageModel {
  id: string;

  url: string;
  path: string;
  userId: string;
  updatedAt: Date | Timestamp;
  createdAt: Date | Timestamp;
  comment: string = '';

  userSnippets: UserSnippet;

  userComments: { [field: string]: any } [] = [];
  // サブコレクション検索用
  docId: string;

  constructor(_id: string, _url: string, _path: string, _user: UserModel, _comment: string='') {
    this.url = _url;
    this.path = _path;
    this.id = _id;
    this.docId = _id;
    this.userId = _user.id;
    this.updatedAt = new Date();
    this.createdAt = new Date();
    if(_comment)
    {
      this.comment = _comment;
    }
    this.userSnippets = {
      name: _user.name as string,
      profileImage: _user.profileImage as string,
      count: _user.count,
      achieveFlags: _user.achieveFlags
    };
  }
}
